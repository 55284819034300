import {
  Box,
  tooltipClasses,
  Tooltip,
  TooltipProps,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { downloadJobReport } from "../../store/jobSlice";
import { useAppDispatch } from "../../store/store";
import CustomLoader from "../custom-loader/custom-loader";
import { CustomAMarkup } from "../../styles/CustomAMarkup";
import { toast } from "react-toastify";
import DownloadIcon from "../../assets/images/download-icon.svg";

let ButtonContainer = styled(Box)<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface Props {
  job: any;
}

function DownloadZipReport(props: Props) {
  const [isProgress, setIsProgress] = useState(false);
  const dispatch = useAppDispatch();

  const downloadReports = async () => {
    if (!props.job || !props.job.pk) return;
    if (isProgress) return;
    setIsProgress(true);

    let result: any = await dispatch(downloadJobReport({ id: props.job.pk }));

    if (downloadJobReport.fulfilled.match(result)) {
      const blob = new Blob([result.payload], {
        type: "application/zip",
      });

      const a = document.createElement("a");
      a.download = `${props.job.name.replaceAll(" ", "_")}_reports.zip`;
      a.href = URL.createObjectURL(blob);
      a.addEventListener("click", (e) => {
        setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
      });
      a.click();
      setIsProgress(false);
    } else {
      toast.error("Problem during downloading reports file", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        pauseOnFocusLoss: false,
        progress: undefined,
        theme: "light",
      });
      setIsProgress(false);
    }
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#566C68",
      color: "#F4FAF9",
      maxWidth: 220,
      borderRadius: 2,
      fontSize: 12,
      borderWidth: 1,
      borderColor: "red",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#566C68",
    },
  }));

  return (
    <>
      <CustomAMarkup>
        <ButtonContainer onClick={downloadReports}>
          <HtmlTooltip
            arrow
            title={
              <React.Fragment>
                <Typography color="inherit">Download Reports</Typography>
              </React.Fragment>
            }
            placement={"top"}
          >
            {isProgress ? (
              <Box sx={{ width: 20 }}>
                <CustomLoader size={15} />
              </Box>
            ) : (
              <img src={DownloadIcon} />
            )}
          </HtmlTooltip>
        </ButtonContainer>
      </CustomAMarkup>
    </>
  );
}

export default DownloadZipReport;
