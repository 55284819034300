import {
  Box,
  Tooltip,
  Typography,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { deleteDraftJob} from "../../store/jobSlice";
import { useAppDispatch } from "../../store/store";
import CustomLoader from "../custom-loader/custom-loader";
import { CustomAMarkup } from "../../styles/CustomAMarkup";
import { GeneralToast } from "../../utils/toasts";
import { setModalContent } from "../../store/utilsSlice";
import ConfirmationModal from "../../modals/confirmation-modal";
import ArrowRightIcon from "../../assets/images/arrow-right.svg";
import {useNavigate} from "react-router-dom";

let ButtonContainer = styled(Box)<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#566C68",
    color: "#F4FAF9",
    maxWidth: 220,
    borderRadius: 2,
    fontSize: 12,
    borderWidth: 1,
    borderColor: "red",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#566C68",
  },
}));

interface Props {
  job: any;
}

function EditDraftJob(props: Props) {
  const [isProgress, setIsProgress] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const deleteJobHandler = async () => {
    navigate(`/summary/${props.job.pk}`)
  };

  return (
    <>
      <CustomAMarkup>
        <ButtonContainer onClick={deleteJobHandler}>
          <HtmlTooltip
            arrow
            title={
              <React.Fragment>
                <Typography color="inherit">Edit Draft</Typography>
              </React.Fragment>
            }
            placement={"top"}
          >
            <Box sx={{ml:2}}>
              <img src={ArrowRightIcon}/>
            </Box>
          </HtmlTooltip>
        </ButtonContainer>
      </CustomAMarkup>
    </>
  );
}

export default EditDraftJob;
