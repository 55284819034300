import { createColumnHelper } from "@tanstack/react-table";
import { Box } from "@mui/material";
import { CustomAMarkup } from "../../styles/CustomAMarkup";
import { NumericFormat } from "react-number-format";
import { store } from "../../store/store";

type User = {
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  remove: any;
};

const columnHelper = createColumnHelper<User>();
export const userColumns = [
  columnHelper.accessor("email", {
    cell: (info) => <Box>{info.getValue()}</Box>,
    // header: () => <Box sx={{ textAlign: "center" }}>Job #</Box>,
    footer: (info) => info.column.id,
    minSize: 200,
    meta: {
      label: "User email address",
    },
    enableSorting: true,
  }),
  columnHelper.accessor("first_name", {
    cell: (info) => <Box>{info.getValue()}</Box>,
    // header: () => <Box sx={{ textAlign: "center" }}>Job #</Box>,
    footer: (info) => info.column.id,
    minSize: 200,
    meta: {
      label: "First Name",
    },
    enableSorting: true,
  }),
  columnHelper.accessor((row) => row.last_name, {
    id: "last_name",
    cell: (info) => <Box>{info.getValue()}</Box>,
    // header: () => <span>End Date</span>,
    footer: (info) => info.column.id,
    minSize: 180,
    meta: {
      label: "Last Name",
    },
    enableSorting: true,
  }),
  columnHelper.accessor((row) => row.role, {
    id: "role",
    cell: (info) => {
      let enums = store.getState().core.enumObject;
      let userRoles: any = {};
      enums["user_roles"].forEach((x: any) => {
        userRoles[x.value] = { name: x.name, label: x.label };
      });
      let role = userRoles[info.getValue()];
      return <Box>{role ? role.label : "-"}</Box>;
    },
    // header: () => <span>End Date</span>,
    footer: (info) => info.column.id,
    minSize: 180,
    meta: {
      label: "Role",
    },
    enableSorting: true,
  }),
  columnHelper.accessor((row) => row.remove, {
    id: "remove",
    cell: (info) => {
      const role = store?.getState()?.user?.loggedUser?.role;
      return (
        <Box display={"flex"} flexDirection={"column"}>
          {role === "admin" && (
            <CustomAMarkup data-custom-action={"remove"}>Remove</CustomAMarkup>
          )}
        </Box>
      );
    },
    // header: () => <span>Actions</span>,
    footer: (info) => info.column.id,
    minSize: 50,
    meta: {
      label: "",
    },
    enableSorting: false,
  }),
];
