import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../utils/requests";
import { JobModel } from "../models/JobType";
import { JobStatModel } from "../models/JobStatModel";

export interface JobState {
  jobs: Array<JobModel>;
  jobListCount: number;
  jobsStats: JobStatModel | null;
  loadingJobListStatus: "idle" | "loading" | "failed" | "fetched" | "submitted";
  patchJobsStatus: "idle" | "loading" | "failed" | "fetched" | "submitted";
  postSetAttrsStatus: "idle" | "loading" | "failed" | "fetched" | "submitted";
  loadingJobsStatsStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
  cancelJobStatus: "idle" | "loading" | "failed" | "fetched" | "submitted";
  restartJobStatus: "idle" | "loading" | "failed" | "fetched" | "submitted";
  deleteDraftJobStatus: "idle" | "loading" | "failed" | "fetched" | "submitted";
  changeUpdateVersionsStatus:
    | "idle"
    | "loading"
    | "failed"
    | "fetched"
    | "submitted";
  // modalContent: any;
}

const initialState: JobState = {
  jobs: [],
  jobListCount: 0,
  jobsStats: null,
  loadingJobListStatus: "idle",
  patchJobsStatus: "idle",
  postSetAttrsStatus: "idle",
  loadingJobsStatsStatus: "idle",
  cancelJobStatus: "idle",
  restartJobStatus: "idle",
  changeUpdateVersionsStatus: "idle",
  deleteDraftJobStatus: "idle",
};

export const getJobList = createAsyncThunk(
  "jobs/getList",
  async (data: any, thunkAPI) => {
    const response = await api.get(`v2/client/jobs`, {
      params: data.queryParams,
    });
    return response.data;
  }
);

export const getJobsStats = createAsyncThunk(
  "jobs/getJobsStats",
  async (data: any, thunkAPI) => {
    const response = await api.get(`v2/client/jobs/stats`, {
      params: data.queryParams,
    });
    return response.data;
  }
);

export const patchJob = createAsyncThunk(
  "jobs/patchJob",
  async (data: any, thunkAPI) => {
    try {
      const response = await api.post(
        `v2/client/jobs/${data.pk}/schedule-optimization-job`,
        data
      );
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const restartJob = createAsyncThunk(
  "jobs/restartJob",
  async (data: any, thunkAPI) => {
    try {
      const response = await api.post(
        `v2/client/jobs/${data.jobPk}/schedule-restarted-job`,
        data
      );
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const postSetAttrs = createAsyncThunk(
  "jobs/postSetAttrs",
  async (data: any, thunkAPI) => {
    try {
      const response = await api.post(
        `v2/client/jobs/${data.pk}/versions-metadata/set-attrs`,
        data.payload
      );
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const createJob = createAsyncThunk(
  "jobs/create",
  async (formData: any, thunkAPI) => {
    try {
      const response = await api.post(`v2/client/jobs`, formData, {
        // params: data.queryParams,
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);
export const cancelJob = createAsyncThunk(
  "jobs/cancelJob",
  async (payload: any, thunkAPI) => {
    try {
      const response = await api.patch(
        `v2/client/jobs/${payload.jobPk}/cancel`,
        {},
        {}
      );
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteDraftJob = createAsyncThunk(
  "jobs/deleteDraftJob",
  async (payload: any, thunkAPI) => {
    try {
      const response = await api.delete(
        `v2/client/jobs/${payload.jobPk}/draft`,
      );
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);


export const setUpdateVersions = createAsyncThunk(
  "jobs/setUpdateVersions",
  async (payload: any, thunkAPI) => {
    try {
      const response = await api.post(
        `v2/client/jobs/${payload.jobPk}/update-versions`,
        payload.formData
      );
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const downloadJobReport = createAsyncThunk(
  "jobs/download-report",
  async (data: any, thunkAPI) => {
    try {
      const response = await api.get(`v2/client/jobs/${data.id}/reports`, {
        responseType: "arraybuffer",
      });
      return response.data;
    } catch (error) {
      throw thunkAPI.rejectWithValue(error);
    }
  }
);

export const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    setCancelJobStatus: (state, action) => {
      state.cancelJobStatus = action.payload;
    },
    setDeleteDraftJobStatus: (state, action) => {
      state.deleteDraftJobStatus = action.payload;
    },
    setRestartJobStatus: (state, action) => {
      state.restartJobStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getJobList.pending, (state) => {
      state.jobListCount = 0;
      state.jobs = [];
      state.loadingJobListStatus = "loading";
    });
    builder.addCase(getJobList.fulfilled, (state, action) => {
      state.jobs = [...action.payload.data];
      state.jobListCount = action.payload.total_count;
      state.loadingJobListStatus = "fetched";
    });
    builder.addCase(getJobList.rejected, (state) => {
      state.jobListCount = 0;
      state.jobs = [];
      state.loadingJobListStatus = "failed";
    });
    builder.addCase(getJobsStats.pending, (state) => {
      state.loadingJobsStatsStatus = "loading";
    });
    builder.addCase(getJobsStats.fulfilled, (state, action) => {
      state.jobsStats = { ...action.payload };
      state.loadingJobsStatsStatus = "fetched";
    });
    builder.addCase(getJobsStats.rejected, (state) => {
      state.loadingJobsStatsStatus = "failed";
    });
    builder.addCase(patchJob.pending, (state) => {
      state.patchJobsStatus = "loading";
    });
    builder.addCase(patchJob.fulfilled, (state, action) => {
      state.jobsStats = { ...action.payload };
      state.patchJobsStatus = "fetched";
    });
    builder.addCase(patchJob.rejected, (state, action) => {
      state.patchJobsStatus = "failed";
    });
    builder.addCase(postSetAttrs.pending, (state) => {
      state.postSetAttrsStatus = "loading";
    });
    builder.addCase(postSetAttrs.fulfilled, (state, action) => {
      state.postSetAttrsStatus = "fetched";
    });
    builder.addCase(postSetAttrs.rejected, (state, action) => {
      state.postSetAttrsStatus = "failed";
    });
    builder.addCase(restartJob.pending, (state) => {
      state.restartJobStatus = "loading";
    });
    builder.addCase(restartJob.fulfilled, (state, action) => {
      state.restartJobStatus = "fetched";
    });
    builder.addCase(restartJob.rejected, (state, action) => {
      state.restartJobStatus = "failed";
    });
    builder.addCase(createJob.pending, (state) => {});
    builder.addCase(createJob.fulfilled, (state, action) => {});
    builder.addCase(createJob.rejected, (state, action) => {});
    builder.addCase(setUpdateVersions.pending, (state) => {
      state.changeUpdateVersionsStatus = "loading";
    });
    builder.addCase(setUpdateVersions.fulfilled, (state, action) => {
      state.changeUpdateVersionsStatus = "fetched";
    });
    builder.addCase(setUpdateVersions.rejected, (state, action) => {
      state.changeUpdateVersionsStatus = "failed";
    });
    builder.addCase(cancelJob.pending, (state) => {
      state.cancelJobStatus = "loading";
    });
    builder.addCase(cancelJob.fulfilled, (state, action) => {
      state.cancelJobStatus = "fetched";
    });
    builder.addCase(cancelJob.rejected, (state, action) => {
      state.cancelJobStatus = "failed";
    });
    builder.addCase(deleteDraftJob.pending, (state) => {
      state.deleteDraftJobStatus = "loading";
    });
    builder.addCase(deleteDraftJob.fulfilled, (state, action) => {
      state.deleteDraftJobStatus = "fetched";
    });
    builder.addCase(deleteDraftJob.rejected, (state, action) => {
      state.deleteDraftJobStatus = "failed";
    });
  },
});

// Action creators are generated for each case reducer function
export const { setCancelJobStatus, setRestartJobStatus, setDeleteDraftJobStatus } = jobSlice.actions;

export default jobSlice.reducer;
